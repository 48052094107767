<template>
  <div class="container-fluid">
    <template v-if="!products.length">
      <loader />
    </template>
    <template v-else>
      <tool-bar />
      <div class="w-100 min-height-screen">
        <router-view />
      </div>
      <whatsAppFix tel="542628887" typeText="מה נשמע זה רפאל, שלח לי הודעה ואספר לך בדיחה. בכל שעה." name="רפאל ליבור"
        sunName='מחובר' image="https://pbs.twimg.com/profile_images/1234389051119194113/pRZsceZV_200x200.jpg" />
      <ageMessage v-if="ifAgeMessage" @close-age-message="closeAgeMessage" />
      <Footer />
    </template>
  </div>
</template>

<script>
  import toolBar from '@/components/toolBar.vue'
  import loader from '@/components/loader.vue'
  import Footer from '@/components/Footer.vue'
  import whatsAppFix from '@/components/chat/whatsAppFix.vue'
  import ageMessage from '@/components/ageMessage.vue'

  export default {
    name: 'App',
    components: {
      toolBar,
      loader,
      Footer,
      whatsAppFix,
      ageMessage
    },
    data() {
      return {
        ifAgeMessage: false
      }
    },
    mounted() {
      // window.onbeforeunload = (e) => {
      //   return "בטוח שתרצה לעזוב:)";
      // };
      this.$store.dispatch("getProducts");
      this.openAgeMessage();
    },
    methods: {
      openAgeMessage() {
        let closeAgeMessage = localStorage.getItem('closeAgeMessage');
        if (!closeAgeMessage) this.ifAgeMessage = true;
      },
      closeAgeMessage() {
        localStorage.setItem('closeAgeMessage', true);
        this.ifAgeMessage = false;
      },
    },
    computed: {
      products() {
        return this.$store.state.products
      }
    }

  }
</script>

<style>

</style>