<template>
  <div class="f-center social-icons">
    <a class="f-center" :href="item.link" target="_blanc" v-for="item in icons" :key="item.id">
      <i :class="item.icon"></i>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'socialIcons',
    data() {
      return {
        icons: [
          {
            icon: "fab fa-facebook-f",
            link: "https://www.facebook.com/חנות-היינות-של-רפאל-105941177639769/",
            id: 1
          },
          {
            icon: "fab fa-instagram",
            link: "http://instagram.com/winefromshomron",
            id: 2
          },
          {
            icon: "fab fa-twitter",
            link: "https://twitter.com/WineOfShomron1?s=09",
            id: 3
          },
          {
            icon: "fab fa-tiktok",
            link: "https://vm.tiktok.com/ZSJ5ckGXr/",
            id: 4
          },
          {
            icon: "fab fa-youtube",
            link: "https://youtube.com/channel/UCyIvttULcNy6Z49Zm_JVz5w",
            id: 5
          },
          {
            icon: "fab fa-linkedin-in",
            link: "https://www.linkedin.com/in/רפאל-ליבור-605a53198",
            id: 6
          },
        ]
      }
    }
  }
</script>

<style scoped>
.social-icons a {
    background-color: #000;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin: 2px;

  }
  .social-icons i {
    margin: 10px;
    cursor: pointer;
    font-size: 77%;
    color: #fff
  }

  /* .social-icons .la-instagram {
    font-size: 35px;
  } */

  /* .social-icons .la-facebook-f {
    font-size: 25px;
  } */



  @media (max-width: 767.98px) {
    .social-icons i {
      margin: 5px;
      /* font-size: 21px; */
    }
  }
</style>