<template>
  <div class="wrap-loader f-center">
    <img src="@/assets/ass1.png" alt="">
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: "loader",
  };
</script>

<style scoped>
  .wrap-loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }

  img {
    width: 200px;
    animation: loader 3s infinite;
  }

  @keyframes loader {
    0% {
      opacity: .5;
      transform: scale(1);
    }

    50% {
      opacity: 1;
      transform: scale(1.1);

    }

    100% {
      opacity: .5;
      transform: scale(1);
    }

  }

  @media (max-width: 767.98px) {}
</style>