<template>
  <!-- <div class="col-12 mt-3"> -->
    <div class="row shadow mt-3 pb-2 pt-2 c-p p-e-p">
      <div class="col-4 img-contain img-product" :style="{backgroundImage: `url(${item.image[0]})`}"></div>
      <div class="col-4 p-0">
        <div class="row h-100 f-center">
          <div class="col-12 f-center">
            <h6 class="fw-light text-center">{{item.name}}</h6>
          </div>
          <div class="col-12 f-center">
            <counter class="w-100 m-0" @customEvent="addAmountProduct" :passAmount="item.amount" :key="amount"
              operation="incroment" />
          </div>
        </div>
      </div>
      <div class="col-4 f-center">
        <h4 class="m-0">₪{{item.amount * item.price}}</h4>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
  import counter from '@/components/counter.vue'
  // import addProductButt from '@/components/addProductButt.vue'

  export default {
    name: 'boxProductInCart',
    components: {
      counter,
      // addProductButt
    },
    props: {
      item: Object
    },
    data() {
      return {
        amount: 1,
      }
    },
      methods: {
      addAmountProduct(e) {
        this.$store.commit('addAmountProduct', {
          amount: e,
          id: this.item.id
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .img-product {
    /* width: 100%; */
    padding-top: 25%;
  }

  @media (max-width: 767.98px) {
  
  }
</style>