<template>
  <nav class="row row-toolbar border-bottom">
    <div class="col-md-4 col-2 f-center-right pe-md-5 pe-3">
      <bar @openClose="openCloseMenu = !openCloseMenu" :openCloseMenu="openCloseMenu" />
      <mainMenu classesOfMenu="pe-3 ps-3 f-center-top" :ifShowMenu="openCloseMenu">
        <categories @openCloseMenu="openCloseMenu = false"/>
      </mainMenu>
    </div>
    <div class="col-md-4 f-center desktop-flex logo">
      <router-link to="/">
        <img class="m-1" src="@/assets/logo.png" alt="logo">
      </router-link>
    </div>
    <div class="col-md-4 col-10 pe-0 f-center-left">
      <socialIcons />
      <div class="border m-3"></div>
      <cart />
    </div>
    <div class="col-md-4 f-center mobile-flex border-top logo">
      <router-link to="/">
        <img class="m-1" src="@/assets/logo.png" alt="logo">
      </router-link>
    </div>
  </nav>
</template>

<script>
  import bar from '@/components/bar.vue'
  import socialIcons from '@/components/socialIcons.vue'
  import cart from '@/components/cart.vue'
  import mainMenu from '@/components/mainMenu.vue'
  import categories from '@/components/categories.vue'

  export default {
    name: 'toolBar',
    components: {
      bar,
      socialIcons,
      cart,
      mainMenu,
      categories
    },
    data() {
      return {
        openCloseMenu: false
      }
    }

  }
</script>

<style scoped>
  .row-toolbar {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
  }

  .border {
    height: 30px;
  }

  .logo img {
    width: 140px;
  }

  .category-items {
    margin: 0;
    padding: 15px 10px;
    width: 100%;
    border-bottom: solid var(--main-text-color) 1px;
    animation-name: items;
    animation-duration: 0.5s;
    pointer-events: painted;
  }

  .category-items:last-child {
    border-bottom: none;
  }

  @keyframes items {
    0% {
      padding: 0px 10px;
    }

    100% {
      padding: 15px 10px;
    }
  }

  @media (max-width: 767.98px) {
    .logo img {
      width: 107px;
    }
  }
</style>