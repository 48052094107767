<template>
  <footer class="row mt-5 font-weight-light">
    <div class="col">
      <div class="row mt-4 f-center">
        <div class="col-md-2 f-center item-in-footer">
          <a href="https://api.whatsapp.com/send?phone=972542628887">
            <p><i class="lab la-whatsapp ms-2"></i>יצירת קשר</p>
          </a>
        </div>
        <div class="col-md-2 f-center item-in-footer">
          <!-- <a href=""> -->
          <p class="c-p" @click="showTerms = !showTerms">תקנון האתר</p>
          <modalTerms v-if="showTerms" @close-modal="showTerms = !showTerms" />
          <!-- </a> -->
        </div>
        <!-- <div class="col-md-2 f-center text-center item-in-footer">

          <p>מבית <a href="https://www.mitkonenet.co.il/" target="_blanc">
              <img class="w-25" src="@/assets/ass2.jpg" alt="">
            </a> </p>

        </div> -->
        <div class="col-md-2 f-center item-in-footer">
          <socialIcons />
        </div>
        <!-- <Modal v-if="showModal" @customEvent="showModal = !showModal">
          <Regulations />
        </Modal> -->
      </div>
      <div class="row mt-3">
        <div class="col f-center">
          <p class="m-2"><a href="https://www.dvirlevi.com" target="_blanc"> נבנה ופותח ע"י DvirLevi | פיתוח אפליקציות ומערכות מורכבות </a></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  // @ is an alias to /src
  import socialIcons from '@/components/socialIcons.vue'
  import modalTerms from '@/components/modalTerms.vue'
  // import Regulations from "@/components/Regulations.vue"

  export default {
    name: 'Footer',
    components: {
      socialIcons,
      modalTerms
      // Regulations
    },
    data() {
      return {
        showTerms: false
      }
    }
  }
</script>

<style>
  footer {
    background-color: var(--main-text-color);
    min-height: 100px;
    color: #fff;
  }

  footer p {
    margin: 0px;
  }

  footer .item-in-footer {
    border-left: solid 1px #fff;
  }

  footer .item-in-footer:last-child {
    border-left: none;
  }

  .item-in-footer a p {
    color: #fff !important;
  }

  @media (max-width: 767.98px) {
    footer .item-in-footer {
      border-left: none;
      padding: 20px 0px;
      position: relative;
    }

    footer .item-in-footer:before {
      content: "";
      border-bottom: 3px solid #fff;
      bottom: 0;
      width: 50px;
      height: 1px;
      display: block;
      position: absolute;
    }

    footer .item-in-footer:last-child:before {
      content: "";
      border-bottom: none;
      bottom: 0;
      width: 50px;
      height: 1px;
      display: block;
      position: absolute;
    }
  }
</style>
