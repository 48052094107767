<template>
  <div id="myModal" class="modal" :style="{backgroundColor: backgroundColor}">
    <!-- Modal content -->
    <div class="modal-con">
      <i class="las la-times x c-p" v-if="isClose" @click.stop="$emit('custom-event')"></i>
      <!-- <img class="x c-p" src="@/assets/ass36.svg" @click.stop="$emit('custom-event')" alt=""> -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: "modal",
    components: {},
    props: {
      backgroundColor: {
        default: "rgba(0, 0, 0, 0.4)",
        type: String
      },
      isClose: {
        default: true,
        type: Boolean
      }
    },
  };
</script>

<style scoped>
  /* The Modal (background) */
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    /* Stay in place */
    z-index: 1001;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
  }

  /* Modal Content */
  .modal-con {
    margin: auto;
    position: relative;
    width: 45%;
    opacity: 1;
    transform: translateY(0px);
    animation-name: op;
    animation-duration: .3s;
  }



  @keyframes op {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .x {
    position: absolute;
    left: 0;
    top: 0;
    margin: 20px 0px 0px 20px;
    color: #000;
    font-size: 30px;
  }

  @media (max-width: 1350px) {
    .modal-con {
      /* padding: 15px; */
      width: 70%;
    }
  }

  @media (max-width: 767.98px) {
    .modal-con {
      /* padding: 15px; */
      width: 90%;
    }

    /* .x {
      width: 15px;
    } */
  }
</style>