<template>
  <div class="w-100 main-color category-items push-items f-right-bottom">
    <h3 class="m-0 fw-bold">קטגוריות</h3>
  </div>
  <div class="wrap-items f-center-top p-e-p">
    <router-link class="category-items w-100 h4 fw-light c-p" @click="$emit('openCloseMenu')"
      v-for="item in $store.getters.categories" :to="item.link" :key="item">
      {{item.category}}
    </router-link>
  </div>

</template>

<script>
  export default {
    name: 'categories',
    components: {

    }
  }
</script>

<style scoped>
  .push-items {
    height: 120px;
  }

  .wrap-items {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }

  .category-items {
    margin: 0;
    padding: 15px 10px;
    width: 100%;
    border-bottom: solid var(--main-text-color) 1px;
    animation-name: items;
    animation-duration: 0.5s;
    pointer-events: painted;
  }

  .category-items:last-child {
    border-bottom: none;
  }

  @keyframes items {
    0% {
      padding: 0px 10px;
    }

    100% {
      padding: 15px 10px;
    }
  }

  @media (max-width: 767.98px) {
     .push-items {
    height: 95px;
  }

  .wrap-items {
    max-height: calc(100vh - 95px);
  }
  }
</style>