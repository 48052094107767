<template>
  <modal backgroundColor="#00000085" :isClose="false" @custom-event="$emit('close-modal')">
    <div class="w-100 h-100 box p-md-5 p-4 pt-5 pb-5 text-center f-center ">
      <h1 class="mt-3">האם את/ה מעל גיל 18?</h1>
      <div class="w-100 f-center mt-3">
        <div class="g-butt h2 p-2 pe-4 ps-4" @click="$emit('close-age-message')">
          אישור
        </div>
      </div>
      <div class="w-100 f-center mt-3">
        <h5 class="main-color">עליך לאשר שהינך מעל גיל 18 בכדי להיכנס לאתר</h5>
      </div>
    </div>
  </modal>
</template>

<script>
  // @ is an alias to /src
  import modal from '@/components/modal.vue'
  // import loader from '@/components/loader.vue'
  //  import aInput from '@/components/aInput.vue'
  // import gtag from "../helpers/gtag.js"

  export default {
    name: "ageMessage",
    components: {
      modal
      // aInput
    },
    props: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {

    },
    computed: {

    }
  };
</script>
<style scoped>
  .box {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 38px 99px #00000029;
    color: #000;
    overflow: hidden;
    border: solid 3px var(--main-color)
  }



  @media (max-width: 767.98px) {}
</style>