<template>
  <div class="f-center position-relative cart c-p" @click="openCloseMenu = !openCloseMenu">
    <i class="las la-shopping-cart pb-1"></i><span class="f-center" :class="animationClass"
      @animationend="endanimation">{{inCart.length}}</span>
    <mainMenu classesOfMenu="f-center-between-column" :ifShowMenu="openCloseMenu">
      <div class="w-100 ">
        <div class="w-100 f-center-between p-3 border-bottom h-100px">
          <h1 class="fw-bold">סל קניות</h1><i class="las la-times p-e-p c-p" @click.stop="openCloseMenu = false"></i>
        </div>
        <div class="row p-4 pt-0 wrap-products p-e-p">
          <div class="col">
            <template v-if="inCart.length">
              <boxProductInCart v-for="item in inCart" :item="item" :key="item.id" />
              <div class="f-center g-butt p-2 mt-3 h4 m-0 w-100 fw-light p-e-p" @click.stop="copyUrl">{{shareLinkButtText}}</div>
            </template>
            <template v-else>
              <h2 class="mt-3">סל קניות ריק :(</h2>
            </template>
          </div>
        </div>
      </div>
      <div class="w-100 p-3 f-center h-100px border-top" v-if="inCart.length">
        <div class="g-butt p-2 h4 m-0 w-100 fw-light p-e-p" @click="$router.push('/Pay')">
          לתשלום ₪{{$store.getters.payable}}
        </div>
      </div>
    </mainMenu>
  </div>
</template>

<script>
  import mainMenu from '@/components/mainMenu.vue'
  import boxProductInCart from '@/components/boxProductInCart.vue'

  export default {
    name: 'cart',
    components: {
      mainMenu,
      boxProductInCart
    },
    data() {
      return {
        animationClass: "",
        openCloseMenu: false,
        shareLinkButtText: "שיתוף עגלה"
      }
    },
    methods: {
      endanimation() {
        this.animationClass = ''
      },
      copyUrl() {

        let urlToCart = `${window.location.origin}?inCart=${encodeURIComponent(JSON.stringify(
          this.inCart.map((el)=>{
            return {
              amount: el.amount,
              id: el.id
            }
          })
        ))}`
        navigator.clipboard.writeText(urlToCart).then( () => {
          this.shareLinkButtText = "הקישור הועתק"
          setTimeout(() => {
            this.shareLinkButtText = "שיתוף עגלה"
          }, 500)
          console.log('Async: Copying to clipboard was successful!')
        }, function (err) {
          console.error('Async: Could not copy text: ', err)
        })
      }
    },
    computed: {
      inCart() {
        return this.$store.getters.inCart;
      }
    },
    watch: {
      inCart: {
        handler() {
          if (this.inCart.length) {
            this.animationClass = 'add-to-cart-animation';
            this.openCloseMenu = true;
          }
          localStorage.setItem('inCart', JSON.stringify(this.inCart))
        },
        deep: true
      }
    }



  }
</script>

<style scoped>
  .cart {
    z-index: 102;
  }

  i {
    font-size: 40px;
  }

  span {
    width: 22px;
    height: 22px;
    background-color: var(--main-text-color);
    color: #fff;
    border-radius: 100px;
    position: absolute;
    top: -4%;
    right: -10%;
    /* transition: .5s; */
  }

  .add-to-cart-animation {
    animation: add-to-cart 1s;
  }

  @keyframes add-to-cart {
    0% {
      width: 22px;
      height: 22px;
      background-color: var(--main-text-color);
    }

    50% {
      width: 32px;
      height: 32px;
      background-color: #14c656;
    }

    100% {
      width: 22px;
      height: 22px;
      background-color: var(--main-text-color);
    }
  }

  .wrap-products {
    max-height: calc(100vh - 170px);
    overflow-y: auto;
  }

  .h-100px {
    height: 85px;
  }

  @media (max-width: 767.98px) {}
</style>