<template>
  <modal backgroundColor="#a6a6a600" @custom-event="$emit('close-modal')">
    <div class="w-100 h-100 box p-md-5 p-4 pt-5 pb-5 modal-info">
      <ul>
        <h2>תקנון האתר</h2>
        <!-- <li>'האדקמיה לאמהות' (להלן: האתר), הינה פלטפורמה לקורסים מצולמים ומקוונים, סדנאות וכנסים במגוון תחומים. השימוש בתכני האתר הינם על פי תנאי השימוש כדלהלן.</li>
        <li>רכישת קורס אפשרית באמצעות סליקת כרטיס אשראי באתר האינטרנט. לאחר ההרשמה לאתר ורכישת הקורס יישלח מייל לכתובת המייל שהוזנה עם שם משתמש וסיסמה. פרטי הגישה לקורס הינם לשימוש אישי בלבד ואין להעביר אותם לאף משתמש אחר.</li>
        <li>שם המשתמש והסיסמה שרכשת יהיו בתוקף למשך חצי שנה (6 חודשים). במידת הצורך תוכלי לפנות אלינו לצורך הארכת התוקף.</li>
        <li>אבטחת המידע באתר נעשית בהתאם לכללי אבטחת המידע המקובלים ובהתאם לנדרש על פי דין.</li>
        <li>השימוש בפרטי אמצעי התשלום של הרוכשים באתר נעשה לשם ביצוע התשלום בלבד. פרטים אלה אינם מועברים לשום
        גורם אחר, למעט גורמים הנדרשים לשם ביצוע התשלום, דוגמת חברת האשראי או הבנק. פרטי כרטיס האשראי <b>מוזנים
        באופן ישיר לאתר חברת הסליקה</b> ולא נשמרים במערכת האתר. </li>
        <li>ברישום לאתר, מודיע בזאת הגולש כי הוא מאפשר לאתר לשלוח מיילים ומסרונים פרסומיים בדבר קורסים ומבצעים שונים, וכן מסרים של אתר 'מתקוננת' ומועדון הלקוחות 'אימוש'. עם זאת, ככל תוכן פרסומי, יש אפשרות למשתמש להסיר עצמו מרשימת התפוצה ע"י כפתור 'הסרה' הנמצא בתחתית כל מייל שיווקי.</li> -->
        <!-- <li>האקדמיה לאימהות עושה כל מאמץ על מנת שלא תצא תקלה תחת ידה ברכישה ובשימוש באתר. אולם לעיתים נגרמות תקלות שלא בשליטתנו. לכן אנו מבקשים מכל מנוי לבדוק את החיובים באופן עצמאי דרך חיובי האשראי והקבלות הנשלחות למייל. גבייה יתירה או טעות בגבייה יתקבלו ויתוקנו בתוך 3 חודשים מיום הגבייה.</li> -->
        <li>ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה), התשע"א-2010 וחוק הגנת הצרכן, התשמ"א-1981</li>
        <li>כתובת: להבה 1 יצהר</li>
        <li>טל': 054-2628887</li>
      </ul>
    </div>
  </modal>
</template>

<script>
// @ is an alias to /src
import modal from "@/components/modal.vue";

export default {
  name: "modalTerms",
  components: {
    modal
  },
};
</script>
<style scoped>
.box {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 38px 99px #00000029;
  color: #000;
}

@media (max-width: 767.98px) {

}
</style>