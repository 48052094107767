<template>
  <div class="wrap-popup f-center">
    <div class="w-100 f-center name-logo p-3">
      <i class="las la-times c-p" @click.stop="$emit('close')"></i>
      <div class="w-25">
        <div class="logo img-cover" :style="{backgroundImage: `url(${image})`}">
          <div class="green-point">

          </div>
        </div>
      </div>
      <div class="w-75 pr-md-0 pr-2">
        <h5>{{name}}</h5>
        <h6 class="fw-light">{{sunName}}</h6>
      </div>

    </div>
    <div class="w-100 f-center-right message p-3">
      <div class="loader f-center p-3" v-if="loader">
        <div class="el-loader dote1"></div>
        <div class="el-loader dote2"></div>
        <div class="el-loader dote3"></div>
      </div>
      <div class="mess" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
          <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
          <path fill="#fff" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
        </svg>
        {{typeText}}
        <p class="w-100 time m-0">
          {{correntTime}}
        </p>
      </div>
      <div class="w-100 type-text mt-3 f-center-top">
        <div class="wrap-text">
          <textarea @click.stop="" class="w-100 p-2" placeholder="ההודעה שלך" v-model="text"></textarea>
        </div>
        <div class="wrap-butt f-center-right pe-2">
          <a :href="`https://wa.me/972${tel}?text=${encodeURIComponent(text)}`" target="_blanc"
            class="send f-center c-p">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
              <path fill="currentColor"
                d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'popup',
    props: {
      tel: String,
      typeText: String,
      name: String,
      sunName: String,
      image: String,
    },
    data() {
      return {
        loader: true,
        text: ""
      }
    },
    mounted() {
      setTimeout(() => {
        this.loader = false;
      }, 3300)
    },
    computed: {
      correntTime() {
        let currentdate = new Date();
        return currentdate.getHours() + ":" + this.addZero(currentdate.getMinutes()) 
      }
    },
    methods: {
       addZero(n) {
        return (n.toString().length <= 1) ? "0" + n : n;
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap-popup {
    width: 360px;
    position: absolute;
    bottom: 115%;
    right: 0;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 12px 24px 0px;
    border-radius: 15px;
    overflow: hidden;
    animation: open .2s;
  }

  @keyframes open {
    from {
      opacity: 0;
      bottom: 0%;
    }

    to {
      opacity: 1;
      bottom: 115%;
    }
  }

  .name-logo {
    background-color: #095e54;
    color: #fff;
    position: relative;
  }

  .la-times {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .name-logo h6 {
    font-size: 14px;
    font-weight: 400;
  }

  .name-logo h5 {
    font-size: 19px;
  }

  .logo {
    width: 70%;
    padding-top: 70%;
    border-radius: 100px;
    position: relative;
  }

  .green-point {
    width: 10px;
    height: 10px;
    background-color: #14c656;
    position: absolute;
    border-radius: 100px;
    bottom: 7%;
    right: 7%;
  }

  .loader {
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 13%) 0px 1px 0.5px;
    position: relative;
    border-radius: 20px;
  }

  .el-loader {
    width: 5px;
    height: 5px;
    margin: 0px 2px;
    background-color: rgb(158, 157, 162);
    border-radius: 100px;
    animation: dotes;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .dote1 {
    animation-delay: 0s
  }

  .dote2 {
    animation-delay: .25s
  }

  .dote3 {
    animation-delay: .5s
  }


  @keyframes dotes {
    0% {
      background-color: rgb(182, 181, 186);
    }

    15% {
      background-color: rgb(17, 17, 17);
    }

    25% {
      background-color: rgb(182, 181, 186);
    }
  }

  .message {
    background-color: rgb(230, 221, 212);
    position: relative;
  }

  .message::before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 0.08;
    background-image: url('https://elfsight.com/assets/chats/patterns/whatsapp.png');
  }

  .message .mess {
    padding: 7px 14px 6px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px 0px 8px 8px;
    position: relative;
    /* transition: all 0.3s ease 0s; */
    /* opacity: 0; */
    /* transform-origin: center top; */
    z-index: 2;
    box-shadow: rgb(0 0 0 / 13%) 0px 1px 0.5px;
    margin-top: 4px;
    margin-left: -54px;
    max-width: calc(100% - 66px);
  }

  .message .mess svg {
    position: absolute;
    right: -7px;
    top: 0px;
  }

  .time {
    font-size: 12px;
  }

  .type-text textarea {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    /* font-size: 20px; */
    position: relative;
    z-index: 3;
    height: 100%;
  }

  .wrap-text {
    width: 80%;
  }

  .wrap-butt {
    width: 20%;
  }

  .send {
    /* width: 35px; */
    /* height: 35px; */
    position: relative;
    background-color: #095e54;
    color: #fff !important;
    font-size: 25px;
    border-radius: 100px;
    padding: 20%;

    /* padding-top: 80%; */
  }

  .send svg {
    transform: rotate(180deg);
  }





  @media (max-width: 767.98px) {
    .wrap-popup {
      /* height: 231px; */
      width: 225px;

    }

    .logo {
      width: 90%;
      padding-top: 90%;
    }

    .name-logo h5 {
      font-size: 17px;
    }

    .name-logo h6 {
      font-size: 12px;
    }

    .message .mess {
      font-size: 15px;
      max-width: calc(100% - 52px);
    }
  }
</style>