import { createStore } from 'vuex'
import getSheet from '../helpers/getSheet'
// import products from "../helpers/products.json"
import postService from '../postService/index'
import router from '@/router/index'

export default createStore({
  state: {
    products: [],
    // messengerPrice: 18,
    ifMessenger: false,
    user: {
      firstName: '',
      lastName: '',
      tel: '',
      mail: '',
      city: '',
      street: '',
      homeNum: '',
      floor: '',
      apartment: '',
      comment: ''
    }
  },
  getters: {
    inCart: (state) => {
      return state.products.filter((el) => {
        return el.amount
      })
    },
    payable: (state, getters) => {
      let sum = 0
      for (let i in getters.inCart) {
        sum += +getters.inCart[i].price * getters.inCart[i].amount
      }
      return +sum.toFixed(2)
    },
    messengerPrice: (state, getters) => {
      if (getters.payable < 350) return 18
      return 0
    },
    allPayable: (state, getters) => {
      let sum = 0
      for (let i in getters.inCart) {
        sum += +getters.inCart[i].price * getters.inCart[i].amount
      }
      if (state.ifMessenger) sum += getters.messengerPrice
      return +sum.toFixed(2)
    },
    categories: (state) => {
      let arr = []
      for (let i in state.products) {
        if (!arr.length)
          arr.push({
            category: state.products[i].category,
            link: `/CategoryPage/${state.products[i].category}`
          })
        let isFound = arr.some((el) => {
          return el.category === state.products[i].category
        })
        if (!isFound)
          arr.push({
            category: state.products[i].category,
            link: `/CategoryPage/${state.products[i].category}`
          })
      }
      return arr
    }
  },
  mutations: {
    pushProducts(state, products) {
      state.products = products
      const inCartFromStorage = JSON.parse(localStorage.getItem('inCart'))
      const inCartFromUrlParams = router.currentRoute._value.query.inCart ? JSON.parse(router.currentRoute._value.query.inCart) : null
      const inCart = inCartFromUrlParams ? inCartFromUrlParams : inCartFromStorage
      if (inCart?.length) {
        for (let i in inCart) {
          for (let x in state.products) {
            if (inCart[i].id === state.products[x].id) {
              state.products[x].amount = inCart[i].amount
            }
          }
        }
      }
    },
    addProduct(state, products) {
      for (let i in state.products) {
        if (state.products[i].id === products.id) state.products[i].amount ? (state.products[i].amount += products.amount) : (state.products[i].amount = products.amount)
      }
    },
    addProductFromUrl(state, {price, name}) {
      state.products.push( {
        "name": name,
        "price": price,
        "previousprice": "",
        "inventory": "6",
        "image": [
          "https://drive.google.com/uc?export=view&id=1zgnzjA3FfkwI1xFr9pT5Ki5kM0WenhVZ"
        ],
        "publish": "1",
        "category": "יקב שילה",
        "id": "fromurl",
        "amount": 1
      })
    },
    addAmountProduct(state, products) {
      for (let i in state.products) {
        if (state.products[i].id === products.id) state.products[i].amount = products.amount
      }
    },
    toggleMessenger(state, ifMessenger) {
      console.log(ifMessenger)
      state.ifMessenger = ifMessenger
    }
  },
  actions: {
    async getProducts(store) {
      try {
        let res = await getSheet('19gof9zJmIRphWv4P91yc7e1I0verFNp8kbV6jIpjAgY')
        // setTimeout(()=> {
        store.commit('pushProducts', res)
        // }, 1500)
      } catch (err) {
        console.log(err)
      }
    },
    async getUrl(store, obj) {
      try {
        let res = await postService.post('/wine-store/get-url-rivhit', obj)
        return res
      } catch (err) {
        console.log(err)
        return false
      }
    }
  },
  modules: {}
})
