<template>
  <div class="bac-menu">
    <transition name="nemu" enter-active-class="open" leave-active-class="close">
      <div :class="classesOfMenu" class="wrap-items" v-if="ifShowMenu" @animationend="closeBac">
        <!-- <router-link class="items-menu" :to="item.link" v-for="item in items" :key="item.id">
          {{item.name}}
        </router-link> -->
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  // import logo from '@/components/logo.vue'

  export default {
    name: 'mainMenu',
    components: {
      // logo
    },
    props: {
      ifShowMenu: Boolean,
      // items: Array,
      classesOfMenu: String
    },
    data() {
      return {


      }
    },
    methods: {
      closeBac() {
        if (!this.ifShowMenu) {
          this.$emit('closeBacMenu')
        }
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .menusToolBar {
    display: none;
  }

  .bac-menu {
    position: fixed;
    width: 100%;
    z-index: 100;
    right: 0;
    top: 0;
    height: 100%;
    pointer-events: none;
  }

  .wrap-items {
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    background: #ffffff;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    box-shadow: -8px 3px 45px #00000029;
  }

  .items-menu {
    margin: 0;
    padding: 15px 10px;
    width: 100%;
    border-bottom: solid var(--main-color) 1px;
    font-size: 21px;
    animation-name: items;
    animation-duration: 0.5s;
    /* animation-delay: 0.5s; */
    font-size: 23px;
    pointer-events: painted;
  }

  .items-menu:last-child {
    border-bottom: none;
  }

  /* .box-img {
    width: 50%;
  } */

  .open {
    animation-name: op;
    animation-duration: 0.4s;
  }

  .close {
    animation-name: close;
    animation-duration: 0.3s;
    opacity: 1;
    font-weight: 400;
  }


  @keyframes op {
    0% {
      right: -100%;
    }

    100% {
      right: 0%;
    }
  }

  @keyframes close {
    0% {
      right: 0%;
    }

    100% {
      right: -100%;
    }
  }

  /* @keyframes items {
    0% {
      padding: 0px 10px;
    }

    100% {
      padding: 15px 10px;
    }
  } */


  @media (max-width: 767.98px) {
    .wrap-items {
      width: 75%;
    }

    .items-menu {
      font-size: 17px;
    }

    /* .box-img {
      width: 100px;
    } */
  }

</style>
