/* eslint-disable */

// const url = 'http://localhost:3000';
const url = 'https://wine-store-s-production.up.railway.app';

const postService = {
   post(path, obj) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url + path, {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj)
        });
        const json = await response.json();
        resolve(json)
      } catch (err) {
        reject(err)
      }
    })
  },
  // get(path) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const myHeaders = {
  //         "Content-Type": "application/json",
  //       }
  //       const response = await fetch(url + path, {
  //         method: 'get',
  //         headers: myHeaders,
  //         // body: objtojson
  //       });
  //       const json = await response.json();
  //       // return json
  //       resolve(json)
  //     } catch (err) {
  //       reject(err)
  //     }
  //   })
  // },
}

export default postService
