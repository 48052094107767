<template>
  <div class="all-fix f-center">
    <div class="wrap-w f-center" @click="isPopup = !isPopup">
      <div>
        <i class="lab la-whatsapp"></i>
      </div>
      <chatPopup v-if="isPopup" @close="closePopup" :tel="tel" :typeText="typeText" :name="name" :sunName="sunName"
        :image="image" />
    </div>
  </div>
</template>

<script>
  import chatPopup from '@/components/chat/chatPopup.vue'

  export default {
    name: 'whatsAppFix',
    components: {
      chatPopup
    },
    props: {
      tel: String,
      typeText: String,
      name: String,
      sunName: String,
      image: String,
    },
    data() {
      return {
        isPopup: false,
      }
    },
    mounted() {
      setTimeout(() => {
        this.openPopup() ;
      }, 7000)
    },
    methods: {
      openPopup() {
        let date = localStorage.getItem('closePopupDate');
        // if(this.timeBefore(date, 1440)) this.isPopup = true
        if(this.timeBefore(date, 720)) this.isPopup = true
      },
      closePopup() {
        localStorage.setItem('closePopupDate', new Date());
        this.isPopup = false
      },
      timeBefore(time, minutesEgo) {
        let c = new Date();
        let b = new Date(time);
        let timeBetween = c.getTime() - b.getTime();
        let minute = 60000;
        if (timeBetween < minutesEgo * minute) return false
        return true
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .all-fix {
    position: fixed;
    bottom: 0;
    z-index: 99;
    padding: 20px;
  }

  i {
    font-size: 60px;
    color: #fff;
  }

  .wrap-w {
    background-color: #14c656;
    width: 85px;
    height: 85px;
    border-radius: 100px;
    position: relative;
    /* box-shadow: 0 0 14px #000000bd; */
  }

  @media (max-width: 767.98px) {
    .all-fix {
      padding: 10px 0px;
    }

    i {
      font-size: 50px
    }

    .wrap-w {
      width: 65px;
      height: 65px;
    }
  }
</style>