import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/ProductPage/:id',
    name: 'ProductPage',
    component: () => import('../views/ProductPage.vue')
  },
  {
    path: '/CategoryPage/:id',
    name: 'CategoryPage',
    component: () => import('../views/CategoryPage.vue')
  },
  {
    path: '/Pay',
    name: 'Pay',
    component: () => import('../views/Pay.vue')
  }
]

const router = createRouter({
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
